import React from 'react';
import { Container } from 'react-bootstrap';
import CityLinkDelivery from './CityLinkDelivery';
import useWindowSize from '../../useWindowsize';

const WhereAreWeDelivery = () => {

    const size = useWindowSize();	
    return (            
        <Container fluid style={{"max-width":"1292px","width":size.width > 767.98 ?"80%":"100%"}}>
            <h2 className="text-center text-danger">Rozcestník kontaktů</h2>
            <div className="mt-4">
                <CityLinkDelivery city_url="karlovy-vary" text="Karlovy Vary"/>
                <CityLinkDelivery city_url="teplice" text="Teplice"/>
                <CityLinkDelivery city_url="usti-nad-labem" text="Ústí nad Labem"/>
                <CityLinkDelivery city_url="melnik" text="Mělník"/>
                <CityLinkDelivery city_url="sokolov" text="Sokolov"/>
                {/* <CityLinkDelivery city_url="chomutov" text="Chomutov"/> */}
                {/* <CityLinkDelivery city_url="decin" text="Děčín"/> */}
                
                <CityLinkDelivery city_url="cheb" text="Cheb"/>
                <CityLinkDelivery city_url="kolin" text="Kolín"/>
                <CityLinkDelivery city_url="louny" text="Louny"/>
                <CityLinkDelivery city_url="litomerice" text="Litoměřice"/>
                <CityLinkDelivery city_url="chodov" text="Chodov"/>
                <CityLinkDelivery city_url="roudnice-nad-labem" text="Roudnice nad Labem"/>
                <CityLinkDelivery city_url="zatec" text="Žatec"/>
                {/* <CityLinkDelivery city_url="ostrava" text="Ostrava"/> */}
                <CityLinkDelivery city_url="olomouc" text="Olomouc"/>
                <CityLinkDelivery city_url="opava" text="Opava"/>
                {/* <CityLinkDelivery city_url="prostejov" text="Prostějov"/> */}
                {/* <CityLinkDelivery city_url="sternberk" text="Šternberk"/> */}
                <CityLinkDelivery city_url="kralupy-nad-vltavou" text="Kralupy nad Vltavou"/>
                <CityLinkDelivery city_url="rakovnik" text="Rakovník"/>
                <CityLinkDelivery city_url="slany" text="Slaný"/>
                <CityLinkDelivery city_url="ceska-lipa" text="Česká Lípa"/>
                <CityLinkDelivery city_url="kladno" text="Kladno"/>
                <CityLinkDelivery city_url="lysa-nad-labem" text="Lysá nad Labem"/>
                <CityLinkDelivery city_url="slany" text="Nové Strašecí - Tuchlovice"/>
                <CityLinkDelivery city_url="kadan" text="Kadaň"/>
                <CityLinkDelivery city_url="turnov" text="Turnov"/>
                <CityLinkDelivery city_url="nymburk" text="Nymburk"/>
                <CityLinkDelivery city_url="podebrady" text="Poděbrady"/>
                <CityLinkDelivery city_url="rumburk" text="Rumburk"/>
                <CityLinkDelivery city_url="varnsdorf" text="Varnsdorf"/>
                <CityLinkDelivery city_url="benesov" text="Benešov"/>
                <CityLinkDelivery city_url="as" text="Aš"/>
            </div>   
        </Container>
    )
}

export default WhereAreWeDelivery
