import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

export const HowItWorks = () => {
    return (
        <Container className="mt-5 " >
            <h3>Rozvoz jídel Karlovy Vary, Teplice, Ústí nad Labem, Mělník, Sokolov, Cheb, Litoměřice, Louny, Kolín, Chodov, Roudnice nad Labem, Žatec, Kralupy nad Vltavou, Slaný, Rakovník, Česká Lípa, Kladno, Lysá nad Labem, Nové Strašecí - Tuchlovice, Kadaň, Turnov, Poděbrady, Nymburk, Rumburk, Varnsdorf
 - restaurace u Vás doma</h3>
            <span className="line" style={{"marginBottom":"20px"}}></span>
            <h6>Je libo hotová jídla, pizza, hamburger, bageta, tatarák, indická, česká kuchyně, čína nebo polední menu? </h6>
            <p style={{"maxWidth":"80%"}}>Nikdy to nebylo jednodušší. Vyberete si typ jídla v Karlových Varech, Teplicích, Ústí nad Labem (pizza, hamburger, bageta, tatarák, česká kuchyně, mexická, čína a další) a my se postaráme o zbytek. Díky našemu systému máte jistotu, že vaše objednávka dorazí do té správné restaurace a že jídlo obdržíte v nejbližší době a nejvyšší kvalitě.</p>
            
            <h6>Je to jednoduché...</h6>
            <Image style={{"maxWidth":"90vw"}} src="https://jidlopodnos.cz/data/soubory/titl/symboly_objednavka.png" />
            <Row className="mt-5">
                <div className="col-md-6 d-flex">
                    <div className="w-50">
                    <h6>Garance kvality</h6>
                    <p>Do portálu jídlopodnos.cz jsme zařadili pouze kvalitní a ověřené restaurace. Díky tomu můžeme ručit za kvalitu dodaného jídla. Pokud nebudete s dodaným zbožím spokojeni, vrátíme vám plnou hodnotu objednávky.
více o garanci kvality</p>
                    </div>
                    <Image className="w-25 ml-3" src="https://jidlopodnos.cz/data/soubory/titl/garance_kvality.png" style={{"height":"fit-content"}}/>
                </div>
                <div className="col-md-6 d-flex">
                    <div className="w-50">
                    <h6>Věrnostní program</h6>
                    <p>Některé z restaurací na portálu jídlopodnos.cz poskytují pro své zákazníky věrnostní program. Při každé objednávce se vám budou připisovat kredity dané restaurace. S našetřenými kredity můžete platit stejným způsobem, jako s běžnými penězi. Stačí je vložit do objednávky a z celkové částky se vám odečte hodnota vložených kreditů.</p>
                    </div>
                    <Image className="w-25 ml-3" src="https://jidlopodnos.cz/data/soubory/titl/vernostni_program.png" style={{"height":"fit-content"}}/>
                </div>
            </Row>
        </Container>
    )
}
